<div class="ps-data-source-settings-container">
  <app-totvs-query-fields
  [dataSource]="dataSource.fields"
  [displayedColumns]="['title', 'description']">
  </app-totvs-query-fields>
</div>

<form [formGroup]="formulario" (ngSubmit)="onSubmit()">
    <div fxLayout="row" class="ps-data-source-settings-container" fxLayoutGap="20px">
      <mat-form-field appearance="fill" fxFlex="18">
        <mat-label>Coligada</mat-label>
        <input matInput #input formControlName="colligate">
      </mat-form-field>
      <mat-form-field appearance="fill" fxFlex="18">
        <mat-label>Cod Sistema</mat-label>
        <input matInput #input formControlName="system">
      </mat-form-field>
      <mat-form-field appearance="fill" fxFlex="18">
        <mat-label>Cod Consulta</mat-label>
        <input matInput #input formControlName="code">
      </mat-form-field>
      <mat-slide-toggle
      color="primary"
      formControlName="use_cache">
      Utilizar cache
    </mat-slide-toggle>
    <mat-form-field *ngIf="formulario.controls.use_cache.value" appearance="fill" fxFlex="20">
      <mat-label>Frequência</mat-label>
      <mat-select formControlName="cache_interval_type_id">
        <mat-option *ngFor="let interval of intervals" [value]="interval.value">{{interval.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="clear-button" fxflex="20">
      <button type="button" mat-button (click)="clear()">
        Limpar
      </button>
    </div>
    </div>
    <div fxLayout="row">
      <div fxFlex="100">
        <mat-divider></mat-divider>
      </div>
    </div>

    <app-adds-pareaments
      nameToggle="Enviar contexto"
      clearParametersOnUncheckToggle="true"
      [identification]="contextConfig"
      [nameFields]="nameFieldsContext"
      [nameFieldsDisabled]="true"
      [systemFields]="systemFields"
      [parameters]="context"
      [formulario]="formulario">
    </app-adds-pareaments>

    <div class="ps-data-source-settings-container">
      <div class="ps-send-param-query-totvs-content" fxLayout="row">
        <div fxFlex>
          <mat-slide-toggle
            color="primary"
            (toggleChange)="changeToggle(matSlideToggle)"
            #matSlideToggle="matSlideToggle"
            formControlName="active_paramn">
            Enviar parâmetros
          </mat-slide-toggle>
        </div>
      </div>
      <div formArrayName="parameters" *ngFor="let item of f.controls; let i = index;">
        <div *ngIf="matSlideToggle.checked" [formGroupName]="i" class="fade-in-bottom" fxLayout="row"
          fxLayoutGap="20px">
          <mat-form-field appearance="fill" fxFlex="20">
            <mat-label>Nome campo</mat-label>
            <input matInput #input formControlName="name">
          </mat-form-field>
          <mat-form-field appearance="fill" fxFlex="20">
            <mat-label>Tipo do campo</mat-label>
            <mat-select formControlName="parameter_type_id">
              <mat-option value="1">Campo sistema</mat-option>
              <mat-option value="2">Valor fixo</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="item.get('parameter_type_id').value == '2'" appearance="fill" fxFlex="20">
            <mat-label>Valor fixo</mat-label>
            <input matInput #input formControlName="fixed_value">
          </mat-form-field>
          <mat-form-field *ngIf="item.get('parameter_type_id').value == '1'" appearance="fill" fxFlex="40">
            <mat-label>Campo sistema</mat-label>
            <mat-select formControlName="field_id">
              <mat-option [value]="field.value" *ngFor="let field of g; let j = index">{{ field.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          <div fxLayoutGap="15px" fxFlex fxLayoutAlign="start center" class="ps-btn-send-param-query-totvs-content">
            <a
              class="ps-send-param-query-totvs-del"
              color="primary"
              mat-stroked-button
              (click)="removeItem(i)">
              <mat-icon>delete</mat-icon>
            </a>
            <a
              class="ps-send-param-query-totvs-add"
              color="primary"
              mat-stroked-button
              (click)="addItemSpecificPosition(i)">
              <mat-icon>add</mat-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
    <app-save-dialog [form]="formulario"></app-save-dialog>
  </form>
